<template>
  <div class="row">
    <div class="col-xs-12 col-sm-8 col-lg-7">
      <validation-observer
        ref="form_update_basic_profile"
        v-slot="{ passes, valid, validated }"
      >
        <form novalidate @submit.prevent="passes(updateProfile)">
          <div class="p-l-10 app-title">{{ $t("auth.profile") }}</div>
          <table
            class="table dataTable app-table-form app-colspan app-colspan-10"
          >
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.mail address") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  type="email"
                  name="email"
                  rules="required"
                  v-model="user.email"
                ></app-input>
              </td>
            </tr>
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.name") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  type="string"
                  name="name"
                  rules="required"
                  v-model="user.name"
                ></app-input>
              </td>
            </tr>

            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.current_password") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  ref="current_password"
                  name="current_password"
                  type="password"
                  rules="required_if:new_password|min:6"
                  autocomplete="new-password"
                  v-model="changePass.old"
                ></app-input>
              </td>
            </tr>

            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.new_password") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  name="new_password"
                  :type="[showNewPassword ? 'text' : 'password']"
                  rules="required_if:current_password|password|min:6"
                  v-model="changePass.new"
                >
                  <template v-slot:append>
                    <i
                      style="font-size: 25px; cursor: pointer"
                      @click="showNewPassword = !showNewPassword"
                      :class="[
                        'fa',
                        showNewPassword ? 'fa-eye' : 'fa-eye-slash',
                      ]"
                    ></i>
                  </template>
                </app-input>
              </td>
            </tr>

            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.new_password_confirm") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  name="new_password_confirm"
                  :type="[showNewConfirmPassword ? 'text' : 'password']"
                  rules="required_if:current_password|confirmed:new_password"
                  v-model="changePass.new_confirm"
                >
                  <template v-slot:append>
                    <i
                      style="font-size: 25px; cursor: pointer"
                      @click="showNewConfirmPassword = !showNewConfirmPassword"
                      :class="[
                        'fa',
                        showNewConfirmPassword ? 'fa-eye' : 'fa-eye-slash',
                      ]"
                    ></i>
                  </template>
                </app-input>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="p-t-20">
                <div class="form-group">
                  <button
                    :disabled="!valid && validated"
                    type="submit"
                    class="app-btn app-btn-blue m-r-10"
                  >
                    {{ $t("common.save") }}
                  </button>
                  <button
                    @click="__redirect(routeDashboard)"
                    type="button"
                    class="app-btn app-btn-orange"
                  >
                    {{ $t("common.cancel") }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { AUTH } from "@constants";

export default {
  name: "ProfilePage",

  data() {
    let routeDashboard =
      AUTH[this.$store.state.auth.currentAuth.role].routes.dashboard;
    return {
      user: {},
      showNewPassword: false,
      showNewConfirmPassword: false,
      changePass: {
        old: null,
        new: null,
        new_confirm: null,
      },
      routeDashboard: routeDashboard,
    };
  },

  mounted() {
    this.$request.get(this.ENDPOINT.PROFILE_USER).then((res) => {
      if (!res.hasErrors()) {
        this.user = res.data.data;
        this.changePass.old = null;
      }
    });
  },

  methods: {
    async updateProfile() {
      const res = await this.$request.post(this.ENDPOINT.PROFILE_UPDATE, {
        name: this.user.name,
        email: this.user.email,
        current_password: this.changePass.old,
        new_password: this.changePass.new,
        new_password_confirm: this.changePass.new_confirm,
      });
      if (!res.hasErrors()) {
        this.changePass = {};
        this.__noticeSuccess(this.$t("common.msg update ok"));
        this.$store.commit(`auth/updateAuth`, {
          user: this.user,
        });
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_basic_profile.setErrors(res.data.errors);
        } else {
          this.__noticeError(res.data?.error_msg);
        }
      }
    },
  },
};
</script>
